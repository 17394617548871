import React, { FC } from 'react';
import ReactModal from 'react-modal';
import styles from './modal.module.css';
import Icon from '@/ui/components/icon';
import Typography from '@/ui/components/typography';
import useTranslation from '@/utils/hooks/useTranslation';

if (process.env.NODE_ENV !== 'test') { ReactModal.setAppElement('#__next'); }

export interface ModalProps extends ReactModal.Props {
    title?: string,
}

const Modal: FC<ModalProps> = ({ onRequestClose, title, children, ...props }) => {
    const modalTitle = title ? <Typography as="p" className="mb-8" variant="h3">{title}</Typography> : null;
    const t = useTranslation();

    return (
        <ReactModal
            bodyOpenClassName="Modal__Body--open"
            closeTimeoutMS={500}
            onRequestClose={onRequestClose}
            overlayClassName={{
                base: styles.overlay,
                afterOpen: styles.overlayAfterOpen,
                beforeClose: styles.overlayBeforeClose,
            }}
            {...props}
        >
            <div className="text-right leading-[0px]">
                <button onClick={onRequestClose} type="button">
                    <Icon
                        className="w-[18px] h-[18px] lg:w-[24px] lg:h-[24px]"
                        name="icon-close"
                        size={18}
                    />
                    <span
                        className="sr-only"
                    >{t('verb_close')}
                    </span>
                </button>
            </div>
            {modalTitle}
            {children}
        </ReactModal>
    );
};

export default Modal;
