import { Center, PromotionPeriod } from '@homebox/client';
import classNames from 'classnames';
import React, { FC, useMemo } from 'react';
import Typography, { TypographyProps } from '@/ui/components/typography';
import { getActivePromotions } from '@/utils/center';
import useTranslation from '@/utils/hooks/useTranslation';

type TagProps = TypographyProps
const Tag: FC<TagProps> = ({ children, className = '', variant, ...props }) => (
    <Typography
        as="span"
        className={
            classNames(
                'py-[1.5px] md:py-1 px-[6px] rounded-[18px] text-center whitespace-nowrap inline-block',
                className,
            )
        }
        variant={variant || 'h7'}
        {...props}
    >{children}
    </Typography>
);

export default Tag;

interface CenterTagProps extends TagProps {
    center: Center,
    className?: string
}

const PromotionTag: FC<TagProps> = ({
    className = '',
    children,
    ...props
}) => (
    <Tag
        className={classNames('bg-lightning-yellow', className)}
        {...props}
    >{children}
    </Tag>
);

type PromotionTagProps = {
    promotions: PromotionPeriod[],
} & TagProps;

const CenterPromotionTag: FC<PromotionTagProps> = ({
    promotions,
    className = '',
    ...props
}) => {
    const now = useMemo(() => new Date(), []);

    const listPromotionPeriod = getActivePromotions(now, promotions);

    const t = useTranslation();

    return (listPromotionPeriod.length > 0
        ? (
            <PromotionTag
                className={className}
                {...props}
            >{t('word_promotion', { count: listPromotionPeriod.length })}
            </PromotionTag>
        ) : null);
};

const BoxTag: FC<CenterTagProps> = ({
    center,
    className = '',
    ...props
}) => {
    const t = useTranslation();

    return (center.small_size && center.tallness_size
        ? (
            <Tag
                className={classNames('bg-provincial-pink', className)}
                {...props}
            >
                {t('center_box_size_range', {
                    SMALLEST_SIZE: center.small_size,
                    TALLEST_SIZE: center.tallness_size,
                })}
            </Tag>
        ) : null);
};

const FunnelTag: FC<{ className?: string }> = ({ children, className }) => (
    <Tag
        className={classNames('!inline-flex !py-[3px] !px-[6px] bg-provincial-pink', className)}
        variant="h6"
    >{children}
    </Tag>
);

export { PromotionTag, CenterPromotionTag, BoxTag, FunnelTag };
