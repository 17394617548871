import classNames from 'classnames';
import React, { FC } from 'react';
import ReactModal from 'react-modal';
import styles from './style.module.css';
import Modal, { ModalProps } from '@/ui/components/modal/';

if (process.env.NODE_ENV !== 'test') { ReactModal.setAppElement('#__next'); }

type LightBoxSizes = 'small' | 'medium' | 'big';

export interface LightBoxProps extends ModalProps {
    size?: LightBoxSizes
}

const Lightbox: FC<LightBoxProps> = ({ isOpen, size = 'small', onRequestClose, children, ...props }) => (
    <Modal
        className={{
            base: classNames(
                styles.lightbox,
                { [styles.lightboxSmall]: size === 'small' },
                { [styles.lightboxMedium]: size === 'medium' },
                { [styles.lightboxBig]: size === 'big' },
            ),
            afterOpen: styles.lightboxAfterOpen,
            beforeClose: styles.lightboxBeforeClose,
        }}
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        {...props}
    >
        {children}
    </Modal>
);

export default Lightbox;
