import { useState } from 'react';

const useQuoteLightBox = () => {
    const [isQuoteLightBoxOpened, setOpenedLightbox] = useState<boolean>(false);

    const openQuoteLightBox = () => {
        setOpenedLightbox(true);
    };

    const closeQuoteLightBox = () => {
        setOpenedLightbox(false);
    };

    return { isQuoteLightBoxOpened, openQuoteLightBox, closeQuoteLightBox };
};

export default useQuoteLightBox;
