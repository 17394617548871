import { AvisVerifie } from '@homebox/client';
import Link from 'next/link';
import React, { FC } from 'react';
import AvisVerifiesLogo from '../../avisVerifiesLogo';
import Button, { ButtonColors, ButtonSizes } from '@/ui/components/button';
import { ModalProps } from '@/ui/components/modal';
import Lightbox from '@/ui/components/modal/lightbox';
import QuoteReview from '@/ui/components/quote/review';
import Star from '@/ui/components/star';
import Typography from '@/ui/components/typography';
import useRouteTranslator from '@/utils/hooks/useRouteTranslator';
import useTranslation from '@/utils/hooks/useTranslation';
import useWebsiteConfig from '@/utils/hooks/useWebsiteConfig';

interface QuotesLightboxProps extends ModalProps {
    averageTotalQuote: number,
    averageTotalValue: number,
    quotes: AvisVerifie[],
    quoteName?: string
}

const QuotesLightbox: FC<QuotesLightboxProps> = ({
    averageTotalQuote,
    averageTotalValue,
    quotes,
    quoteName = 'HOMEBOX',
    ...props
}) => {
    const { isEurope } = useWebsiteConfig();

    const averagePercentage = Math.round((Number(averageTotalQuote) / 5) * 100);
    const t = useTranslation();
    const translateRoute = useRouteTranslator();
    const avisVerifeLink = isEurope ? 'https://www.homebox.fr/avis-verifies' : translateRoute('AVIS_VERIFIE');

    return (
        <Lightbox {...props} size="medium">
            <div className="flex flex-col h-full">
                <div>
                    <Typography
                        as="h2"
                        variant="h2"
                    >
                        {t(
                            'avis_verifies_title_satisfaction_percent',
                            {
                                percentage: `${averagePercentage}`,
                                name: quoteName,
                            },
                        )}
                    </Typography>
                    <div className="flex items-center space-x-2 mt-3">
                        <AvisVerifiesLogo variant="gray" />
                        <div>
                            <Star numberOfStar={averageTotalQuote} />
                        </div>
                        <Typography variant="tiny">
                            {t('rating_average', { AVERAGE: averageTotalQuote, TOTAL: averageTotalValue })}
                        </Typography>
                    </div>
                </div>
                <div className="grid md:grid-cols-2 gap-y-8 md:gap-y-[60px] md:gap-x-10 md:mt-[60px] overflow-auto">
                    {quotes && quotes.map((quote: any) => (
                        <QuoteReview
                            key={quote.review_id.toString()}
                            quote={quote}
                        />
                    ))}
                </div>
                <div className="mt-10 text-center">
                    <Link href={avisVerifeLink}>
                        <a>
                            <Button
                                asElement="a"
                                colorVariant={ButtonColors.GREEN}
                                sizeVariant={ButtonSizes.MEDIUM}
                            >

                                {t('see_all_reviews')}
                            </Button>
                        </a>
                    </Link>
                </div>
            </div>
        </Lightbox>
    );
};

export default QuotesLightbox;
