import { FC } from 'react';

const Section: FC<{ className?: string }> = ({ className = '', children }) => (
    <section
        className={`px-[24px] py-[48px] md:py-[64px] 2xl:py-[104px] 2xl:px-[40px] ${className}`}
    >{children}
    </section>
);

export default Section;
