import React, { Component, FC, HTMLAttributes, ReactNode } from 'react';
import Section from './section';
import Wrapper from '@/ui/components/wrapper';

const WrappedSection: FC<{ header?: ReactNode } & HTMLAttributes<Component>> = ({ header, children, ...props }) => (
    <Section {...props}>
        <Wrapper>
            {header && (
                <header className="mb-[24px] md:mb-10 lg:mb-[64px] text-center">
                    {header}
                </header>
            )}
            {children}
        </Wrapper>
    </Section>
);

export default WrappedSection;
