import classNames from 'classnames';
import React, { FC, HTMLAttributes } from 'react';
import Icon from '@/ui/components/icon';

const SliderArrow: FC<{
    iconName: string,
    iconClassName?: string,
    buttonClassName?: string
} & HTMLAttributes<HTMLButtonElement>> = ({
    className,
    buttonClassName,
    iconName,
    iconClassName,
    ...props
}) => (
    <button type="button" {...props} className={classNames('slick-arrow bg-red', className, buttonClassName)}>
        <div className="flex items-center justify-center h-full">
            <Icon
                className={classNames('text-black slick-arrow-icon', iconClassName)}
                name={iconName}
                size={18}
            />
        </div>
    </button>
);

export default SliderArrow;
