import { AvisVerifie } from '@homebox/client';
import React, { FC } from 'react';
import useQuoteLightBox from './hook/useLightBox';
import { QuoteInlineProps } from './index';
import QuotesLightbox from '@/ui/components/homepage/lightbox/quotes';
import Star from '@/ui/components/star';
import Typography from '@/ui/components/typography';
import useTranslation from '@/utils/hooks/useTranslation';
import useWebsiteConfig from '@/utils/hooks/useWebsiteConfig';

const SimpleQuote: FC<{ className?: string, quotes: AvisVerifie[] } & Omit<QuoteInlineProps, 'logoColor'>> = ({
    totalValue,
    average,
    quotes,
    className = '',
    center,
}) => {
    const { isQuoteLightBoxOpened, openQuoteLightBox, closeQuoteLightBox } = useQuoteLightBox();
    const t = useTranslation();
    const { showAvisVerifies } = useWebsiteConfig();

    if (!showAvisVerifies) {
        return null;
    }

    return (
        <>
            <button
                className={`flex items-center space-x-2 ${className}`}
                onClick={openQuoteLightBox}
                type="button"
            >
                <Star numberOfStar={average} />
                <Typography variant="tiny">
                    {t('rating_total', { TOTAL: totalValue })}
                </Typography>
            </button>
            <QuotesLightbox
                averageTotalQuote={Number(average)}
                averageTotalValue={totalValue}
                isOpen={isQuoteLightBoxOpened}
                onRequestClose={closeQuoteLightBox}
                quoteName={center?.name}
                quotes={quotes}
            />
        </>
    );
};
export default SimpleQuote;
