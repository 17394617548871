import { Media, PromotionPeriod } from '@homebox/client';
import classNames from 'classnames';
import Image from 'next/image';
import React, { FC, useMemo, useState } from 'react';
import styles from './style.module.css';
import VirtualVisitButton from '@/ui/components/button/virtualVisit';
import Lightbox from '@/ui/components/modal/lightbox';
import VirtualVisit from '@/ui/components/virtual-visit';
import { getActivePromotions } from '@/utils/center';

const PromotionsGrid: FC<{ promotions: PromotionPeriod[] }> = ({ promotions }) => {
    const now = useMemo(() => new Date(), []);

    const filteredPromotions = getActivePromotions(now, promotions);

    return (
        <div
            className={classNames(
                'grid gap-2 grid-cols-[repeat(auto-fit,72px)]',
                'justify-center w-full max-w-[80%] max-h-full',
                'absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 z-10',
                'md:grid-cols-[repeat(auto-fit,144px)] overflow-hidden',
            )}
        >
            {filteredPromotions.filter(
                (promotion) => promotion.highlight,
            ).map(
                (promotionPeriod) => promotionPeriod.promotion && (
                    <div key={promotionPeriod.id} className="w-[72px] h-[72px] md:w-[144px] md:h-[144px] w-full h-full">
                        <Image
                            height={72}
                            layout="responsive"
                            sizes="(min-width:640px) 144px, 72px"
                            src={promotionPeriod.promotion.icon.url}
                            width={72}
                        />
                    </div>
                ),
            )}
        </div>
    );
};

const ImageGrid: FC<{
    images: [Media, Media, Media],
    promotions: PromotionPeriod[],
    onClickImage: () => void,
    type?: 'center' | 'particular',
    className?: string,
    virtualVisitLink?: string,
}> = ({
    images,
    promotions,
    onClickImage,
    type = 'center',
    className = '',
    virtualVisitLink,
}) => {
    const [openedVirtualVisitPoppin, setOpenedVirtualVisitPoppin] = useState<boolean>(false);
    const css = 'grid-cols-[1fr_33%]';
    const cssCellsPlace: string[] = [
        'col-start-1 row-span-2',
        'col-start-2 row-start-1',
        'col-start-2 row-start-2',
    ];
    const imageSizes: [number, number][] = [
        [660, 442],
        [281, 216],
        [281, 218],
    ];

    return (
        <>
            <div className={`grid gap-2 ${css} ${className}`}>
                {images.map((image, index: number) => (
                    <div
                        key={image.id}
                        className={classNames(styles.imageGridCell, cssCellsPlace[index])}
                    >
                        <div
                            className={
                                styles.imageGridCellInner
                            }
                            onClick={onClickImage}
                            onKeyUp={(event) => {
                                if (event.code.toLowerCase() === 'enter') {
                                    onClickImage();
                                }
                            }}
                            role="button"
                            tabIndex={-1}
                        >
                            <Image
                                alt="HOMEBOX"
                                height={imageSizes[index][1]}
                                {...index === 0 && { quality: 90 }}
                                src={image.url}
                                width={imageSizes[index][0]}
                            />
                        </div>
                        {type === 'center' && index === 0 && (
                            <>
                                <PromotionsGrid promotions={promotions} />
                                <VirtualVisitButton
                                    className={classNames(styles.virtualVisitButton, 'md-down:!hidden')}
                                    onClick={() => setOpenedVirtualVisitPoppin(true)}
                                />
                            </>
                        )}
                    </div>
                ))}
            </div>
            {type === 'center' && (
                <VirtualVisitButton
                    className={classNames('mt-2 justify-self-start md:!hidden')}
                    onClick={() => setOpenedVirtualVisitPoppin(true)}
                />
            )}
            {openedVirtualVisitPoppin && type === 'center' && (
                <Lightbox
                    isOpen={openedVirtualVisitPoppin}
                    onRequestClose={() => setOpenedVirtualVisitPoppin(false)}
                    size="big"
                >
                    <VirtualVisit
                        virtualVisitLink={virtualVisitLink}
                    />
                </Lightbox>
            )}
        </>
    );
};

export default ImageGrid;
