import React, { ElementType, FC } from 'react';
import Button, { ButtonColors, ButtonProps, ButtonSizes } from '@/ui/components/button';
import Icon from '@/ui/components/icon';
import useTranslation from '@/utils/hooks/useTranslation';

const VirtualVisitButton: FC<ButtonProps<ElementType>> = ({ className, onClick }) => {
    const t = useTranslation();

    return (
        <Button
            className={className}
            colorVariant={ButtonColors.GRAY}
            onClick={onClick}
            sizeVariant={ButtonSizes.MEDIUM}
        >
            <Icon
                name="icon-virtual"
                size={18}
            />
            {t('virtual_visit')}
        </Button>
    );
};

export default VirtualVisitButton;
