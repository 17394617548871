import React, { FC } from 'react';

const Separator: FC<{ className?: string, rounded?: boolean }> = ({ className = '', rounded = false }) => {
    const css = rounded ? 'w-[4px] h-[4px] bg-grey-stroke border-0 inline-block rounded' : 'w-full h-0 mt-8 border-t-grey-stroke';

    return (
        <hr className={`${css} ${className}`} />
    );
};

export default Separator;
