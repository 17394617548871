import classNames from 'classnames';
import { FC, ReactNode } from 'react';

type WrapperProps = {
    children: ReactNode,
    className?: string
}

const Wrapper: FC<WrapperProps> = ({ children, className }) => (
    <div className={classNames('w-full max-w-[1200px] mx-auto', className)}>{children}</div>);

export default Wrapper;
