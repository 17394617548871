import React, { FC } from 'react';
import Typography from '@/ui/components/typography';
import useTranslation from '@/utils/hooks/useTranslation';
import useWebsiteConfig from '@/utils/hooks/useWebsiteConfig';

const VirtualVisit: FC<{ virtualVisitLink?: string }> = ({ virtualVisitLink }) => {
    const t = useTranslation();
    const { defaultVirtualVisit } = useWebsiteConfig();
    const virtualVisit = virtualVisitLink || defaultVirtualVisit;

    return (
        <div className="overflow-y-auto grow flex flex-col">
            <Typography as="p" variant="h3">{t('virtual_visit')}</Typography>
            <iframe
                allow="fullscreen"
                className="w-full grow mt-10"
                src={virtualVisit}
                title={t('virtual_visit')}
            />
        </div>
    );
};

export default VirtualVisit;
